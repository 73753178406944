import React, { useEffect, useState } from "react";
import SideModal from "../SideModal/SideModal";
import ModalText from "../Text/Modal.text";
import { AdminService } from "../../services/admin.service";
import { Badge, Button, Dropdown, Form } from "react-bootstrap";
import { IoCopy, IoTrashBinSharp } from "react-icons/io5";
import toast from "react-hot-toast";
import ConfirmationModal from "./Confirmation.modal";
import ConfirmationGlobalModal from "./Confirmation.global.modal";

interface IPaymentLinkDetail {
  paymentId: boolean;
  onHide: any;
}

export default function PaymentLinkDetailModal(props: IPaymentLinkDetail) {
  const [plDetail, setPlDetail] = useState<any>(undefined);
  const [showConfirm, setShowConfirm] = useState(false);

  const getPaymentLinkDetail = async () => {
    await AdminService.getPaymentLink(props.paymentId)
      .then((res) => {
        setPlDetail(res.data.details);
      })
      .catch((e) => {
        console.log(e);
      });
  };


  const markCompleted = async () => {
    if(!props.paymentId) return;
    await AdminService.markPaymentLinkCompleted(props.paymentId).then((res) => {
      if (res.status === 200) {
        toast.success("Marked as Completed");
      }
    }).catch(e => {
      toast.error("Something went wrong");
      toast.error(e.response.data);
    });
  }

  useEffect(() => {
    if (!props.paymentId) return;
    getPaymentLinkDetail();
  }, [props.paymentId]);

  if (!plDetail) {
    return <></>;
  }

  return (
    <>
    <SideModal
      title={"Payment Link Detail"}
      show={props.paymentId ? true : false}
      handleClose={props.onHide}
      body={
        <>
          <ModalText label="Payment Link ID" value={plDetail?._id} />
          <ModalText label="Student Name" value={plDetail?.studentId?.firstName + " " + plDetail?.studentId?.lastName} />
          <ModalText label="Country" value={plDetail?.country} />
          <ModalText label="Total Session" value={plDetail?.totalSession} />
          <div className="d-flex flex-row">
            <ModalText label="Status" value={""} />
            {plDetail?.paymentStatus === "pending" ? (
              <td>
                <u className="fst-italic text-primary fs-12 cursor-pointer">
                  <Badge bg="danger" className="me-1">
                    {plDetail?.paymentStatus?.toUpperCase()}{" "}
                  </Badge>
                </u>
              </td>
            ) : plDetail?.paymentStatus === "partial-paid" ? (
              <td>
                <u className="fst-italic text-primary fs-12 cursor-pointer">
                  <Badge bg="warning" className="me-1">
                    {plDetail?.paymentStatus?.toUpperCase()}{" "}
                  </Badge>
                </u>
              </td>
            ) : (
              <td>
                <u className="fst-italic text-primary fs-12 cursor-pointer">
                  <Badge bg="success" className="me-1">
                    {plDetail?.paymentStatus?.toUpperCase()}{" "}
                  </Badge>
                </u>
              </td>
            )}
          </div>

          {plDetail?.paymentDetails && (
            <div className="d-flex flex-row align-items-center gap-2">
              <ModalText
                label="URL"
                value={plDetail?.paymentDetails?.short_url}
              />
              <div className="d-flex flex-row gap-1">
                {plDetail?.paymentDetails?.short_url && (
                  <Dropdown.Item
                    className="text-secondary fs-12 align-items-center d-flex fw-bold"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        plDetail?.paymentDetails?.short_url
                      );
                      toast.success("Link Copied");
                    }}
                  >
                    <IoCopy className="text-secondary me-1" />
                  </Dropdown.Item>
                )}

               
              </div>
            </div>
          )}

          <div className="card mt-5">
            <div className="card-header text-secondary">Payment Details</div>
            <ul className="list-group list-group-flush p-2">
              <ModalText
                label="Amount"
                value={"Rs. " + plDetail?.paymentDetails?.amount / 100}
              />
              <ModalText
                 label="Part Paid (if any)"
                value={
                  plDetail?.paymentPartialObject?.payload?.payment?.entity?.amount 
                    ? "Rs. " +  plDetail?.paymentPartialObject?.payload?.payment?.entity?.amount / 100
                    : "Rs. 0"
                }
              />
            </ul>
          </div>
          <div>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Dropped Partially paid Lead?</Form.Label>
              <Button
                className="btn btn-primary mt-3"
                onClick={() => {
                 setShowConfirm(true);
                }}>
                Mark as Completed (Partial Paid - RZP)
              </Button>
            </Form.Group>
          </div>
          
        </>
      }
    />
    <ConfirmationGlobalModal
      show={showConfirm}
      setShow={setShowConfirm}
      onSuccess={() => { 
        markCompleted() 
        setShowConfirm(false);
      }
      }
      alertBody={"Are you sure you want to mark this as completed?"}
      body={<>
        This will mark the payment link as completed, an invoice of partially paid enrollment would be generated and the student wouldnot be able to make a payment <b>(Only Supported on: Razorpay - Partially Paid Links)</b>
        </>
      }
    />
    </>
  );
}
