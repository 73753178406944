import React from 'react'
import { Button, Modal } from 'react-bootstrap'

interface IConfirmationModal {
    show: any,
    setShow: any,
    onSuccess: any
    alertBody: any
    body: any
}

const ConfirmationGlobalModal = (props: IConfirmationModal) => {

    const modalStyle = {
        width: "600px",
        margin: "20% 34%",
    };


    return (
        <Modal
            show={props.show}
            onHide={() => props.setShow(false)}
            size="lg"
            style={{ ...modalStyle }}
        >
            <Modal.Header closeButton>
                <Modal.Title>Confirm your Activity.</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="alert alert-danger">
                    {props.alertBody}
                </div>
                <div>
                    {props.body}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={() => props.onSuccess()}>
                    Yes
                </Button>
                <Button variant="secondary" onClick={() => props.setShow(false)}>
                    No, go back
                </Button>
            </Modal.Footer>
        </Modal >
    )
}

export default ConfirmationGlobalModal