import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Tab, Tabs } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import { AdminService } from "../../services/admin.service";
import { onFetchInstructorCourse } from "../../store/actions/InstructorCourse";
import { useAppDispatch, useAppSelector } from "../../store/reduxHooks";
import Select from "react-select";
import { IcreateRazorpayLink } from "../../interfaces/razorpay.interface";
import toast from "react-hot-toast";
import { ArrayHelper } from "../../helpers/array.helper";
import DatePicker from "react-datepicker";
import moment from "moment";
import SideModal from "../SideModal/SideModal";
import { states } from "../../utilis/states.utils";
import { countries } from "../../utilis/country.utiils";
import { BookingService } from "../../services/booking.service";

interface ICreateIncentiveLinkProps {
  show: boolean;
  setShow: any;
  reload: any;
}

export default function CreateIncentiveLinkModal(
  props: ICreateIncentiveLinkProps
) {
  const [selectedEnrollments, setSelectedEnrollments] = useState<any>();
  const [paidEnrollments, setPaidEnrollment] = useState<any>();
  const [date, setDate] = useState<any>();
  const [amount, setAmount] = useState<any>();
  const [sessionCount, setSessionCount] = useState<any>();
  const [loading, setLoading] = useState(false);

  // const [inputStudentValue, setInputStudentValue] = useState<any>();
  const [selOption, setSelOption] = useState<any>({});
  // const [instructorCourses, setInstructorCourses] = useState<any>({});
  const [inputCourseValue, setInputCourseValue] = useState<any>();
  // const id = selOption?.value || "";

  const getAllPaidSessions = async () => {
    await BookingService.getAllPaidEnrollments().then((res) => {
      if (res.status === 200) {
        const select_data = res.data.data.map((data: any) => {
          return {
            label:
              data.student_name +
              " - " +
              data.instructor_name +
              " - " +
              data.instrument +
              " - " +
              data.enrollmentId +
              " (" +
              data.sessions_left +
              "/" +
              data.total_classes +
              ")",
            value: data._id,
          };
        });
        setPaidEnrollment(select_data);
      }
    });
  };
  // const fetchStudentData = (inputValue: any, callback: ([]) => void) => {
  //   if (!inputValue) {
  //     callback([]);
  //   } else {
  //     setTimeout(() => {
  //       AdminService.getAllStudents(inputValue).then((res) => {
  //         if (res.status === 200) {
  //           const students = res.data.data.map((data: any) => {
  //             return {
  //               value: data._id,
  //               label: `${data.firstName}  ${data.lastName} - ${data.email}`,
  //             };
  //           });
  //           callback(students);
  //         }
  //       });
  //     }, 1000);
  //   }
  // };
  // const fetchInstructorData = (inputValue: any, callback: ([]) => void) => {
  //   if (!inputValue) {
  //     callback([]);
  //   } else {
  //     setTimeout(() => {
  //       AdminService.getAllInstructor(inputValue).then((res) => {
  //         if (res.status === 200) {
  //           const instructors = res.data.data.map((data: any) => {
  //             return {
  //               value: data._id,
  //               label: `${data.firstName}  ${data.lastName} - ${data.email}`,
  //             };
  //           });
  //           callback(instructors);
  //         }
  //       });
  //     }, 1000);
  //   }
  // };

  // const fetchCourseData = () => {
  //   AdminService.getInstructorCourses({ id }).then((res) => {
  //     if (res.status === 200) {
  //       setInstructorCourses(
  //         res?.data?.data?.map((data: any) => {
  //           return {
  //             value: data._id,
  //             label: `${data.title}`,
  //           };
  //         })
  //       );
  //     }
  //   });
  // };

  useEffect(() => {
    getAllPaidSessions();
  }, []);

  // useEffect(() => {
  //   fetchCourseData();
  // }, [id])

  const onSubmit = async () => {
    if (!amount || !date || !sessionCount || !selectedEnrollments) {
      toast.error("Please fill all the fields");
      return;
    }

    setLoading(true);

    const data = {
      enrollmentId: selectedEnrollments?.value,
      date: moment(date),
      amount: amount,
      sessionCount: sessionCount,
    };
    await AdminService.createIncentive(data).then((res) => {
      if (res.status === 200) {
        toast.success("Incentive Created Successfully");
        setLoading(false);
        props.setShow(false);
        props.reload();
      } else {
        toast.error("Something went wrong");
        setLoading(false);
      }
    });
  };

  return (
    <SideModal
      title={"Create Incentive"}
      show={props.show}
      handleClose={() => props.setShow(false)}
      body={
        <>
          <Form.Group className="mt-2">
            <Form.Label className="fw-bold text-secondary fs-12">
              Select Enrollnment
            </Form.Label>
            <Select
              options={paidEnrollments}
              value={selectedEnrollments}
              isSearchable={true}
              onChange={(e: any) => {
                setSelectedEnrollments(e);
              }}
              isClearable={true}
            />
          </Form.Group>
          {/* <Form.Group className="mt-2">
            <Form.Label className="fw-bold text-secondary fs-12">
              Select Guru
            </Form.Label>
            <AsyncSelect
              name="InstructorId"
              loadOptions={fetchInstructorData}
              onChange={(option) => handelInstructorChanges(option)}
              isClearable={true}
            />
          </Form.Group> */}
          {/* <Form.Group className="mt-2">
            <Form.Label className="fw-bold text-secondary fs-12">
              Select the Student
            </Form.Label>

            <AsyncSelect
              name="studentId"
              loadOptions={fetchStudentData}
              onChange={setInputStudentValue}
              isClearable={true}
            />
          </Form.Group> */}
          {/* <Form.Group className="mt-2">
            <Form.Label className="fw-bold text-secondary fs-12">
              Select the Course
            </Form.Label>
            <Select
              name="courseId"
              options={instructorCourses}
              isClearable={true}
              onChange={setInputCourseValue}
            />
          </Form.Group> */}
          <Form.Group className="mt-2">
            <Form.Label className="fw-bold text-secondary fs-12">
              Select Date
            </Form.Label>
            <DatePicker
              className="form-control"
              selected={date}
              onChange={(date: any) => setDate(date)}
              dateFormat="dd/MM/yyyy"
              placeholderText="Select Date"
            />
          </Form.Group>
          <Form.Group className="mt-2">
            <Form.Label className="fw-bold text-secondary fs-12">
              Amount
            </Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mt-2">
            <Form.Label className="fw-bold text-secondary fs-12">
              Session Count
            </Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Session Count"
              value={sessionCount}
              onChange={(e) => setSessionCount(e.target.value)}
            />
          </Form.Group>
          <Button className="mt-4" onClick={onSubmit} disabled={loading}>
            Create
          </Button>
        </>
      }
    />
  );
}
