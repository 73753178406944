export enum UserRoleEnum {
  SEARCH = "search",
  SEARCH_INSTRUCTOR = "search-instructor",
  SESSIONS = "sessions",
  DISCOUNTS = "discounts",
  BULKBOOKING = "bulkBooking",
  DEMOBOOKING = "demoBooking",
  PAYMENTLINKS = "paymentLinks",
  MESSAGES = "messages",
  INSTRUCTOR_APPROVAL = "instructor-approval",
  EXCELDATA = "excelData",
  RECORDING = "recording",
  ROLE = "role",
  INVOICES = "invoices",
  INSTRUCTOR_SEARCH = "instructor-search",
  ANNOUNCEMENTS = "announcements",
  RENEWALS = 'renewals',
  CALL_RECORDING = 'call-recording',
  BLOCKED_ENROLLMENTS = "blocked-enrollments",
  UNPAID_INVOICES = "unpaid-invoices",
  INCENTIVES = "incentives",
  VERSION = "version"
}

