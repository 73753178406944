import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  Form,
  Pagination,
  Row,
} from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import toast from "react-hot-toast";
import CardBasic from "../../../components/Card/card.basic";
import CreateRazorpayLinkModal from "../../../components/Modals/CreateRazorpay.modal";
import RazorpayTable from "../../../components/Table/Razorpay.table";
import { AdminService } from "../../../services/admin.service";
import AsyncSelect from "react-select/async";

import Select from "react-select";
import PaymentLinkDetailModal from "../../../components/Modals/PaymentLinkDetail.modal";
import { CSVLink } from "react-csv";

const razorPayStatus = [
  {
    value: "pending",
    label: "Pending",
  },
  {
    value: "completed",
    label: "Completed",
  },
  {
    value: "failed",
    label: "Failed",
  },
  {
    value: "partial-paid",
    label: "Partial Paid",
  },
];

const gateways = [
  {
    value: "rzp",
    label: "Razorpay",
  },
  {
    value: "paytm",
    label: "Paytm",
  },
  {
    value: "cashfree",
    label: "Cashfree",
  },
];

export default function RazorPayIndex() {
  const [showCreateLink, setShowCreateLink] = useState(false);
  const [tableData, setTableData] = useState<any>();
  const [totalPages, setTotalPages] = useState(20);
  const [pageCount, setPageCount] = useState(1);
  const [status, setStatus] = useState<any>({
    value: "",
    label: "",
  });
  const [selOption, setSelOption] = useState<any>();

  const [gateway, setGateway] = useState<any>({});

  const [csvData, setCsvData] = useState<any>([]);

  const [dateRange, setDateRange] = useState({
    startDate: undefined,
    endDate: undefined,
  });

  const handelInstructorChanges = (obj: any, name: string) => {
    setSelOption({ ...selOption, [name]: obj });
  };

  const fetchStudentData = (inputValue: any, callback: ([]) => void) => {
    if (!inputValue) {
      callback([]);
    } else {
      setTimeout(() => {
        AdminService.getAllStudents(inputValue).then((res) => {
          if (res.status === 200) {
            const students = res.data.data.map((data: any) => {
              return {
                value: data._id,
                label: `${data.firstName}  ${data.lastName} - ${data.email}`,
              };
            });
            callback(students);
          }
        });
      }, 1000);
    }
  };

  const getPaymentData = async () => {
    await AdminService.getAllPaymentDetails(
      moment(dateRange.startDate).format("YYYY-MM-DD"),
      moment(dateRange.endDate).format("YYYY-MM-DD"),
      totalPages,
      pageCount,
      status.value,
      gateway.value,
      selOption?.student?.value
    )
      .then((res) => {
        if (res.status === 200) {
          setTableData(res?.data?.data?.paymentDetails);
        }
      })
      .catch((err) => {
        toast.error("Unable to fetch Payment Details");
      });
  };

  const createCSVFile = async () => {
    await AdminService.getAllPaymentDetails(
      moment(dateRange.startDate).format("YYYY-MM-DD"),
      moment(dateRange.endDate).format("YYYY-MM-DD"),
      999999999,
      1,
      status.value,
      gateway.value,
      selOption?.student?.value
    )
      .then((res) => {
        if (res.status === 200) {

          const csvHeader = [
            "ID/ORDERID",
            "STUDENT NAME",
            "TEACHER NAME",
            "COUNTRY",
            "COURSE",
            "COORDINATOR NAME",
            "SESSIONS",
            "EXTRA SESSIONS",
            "AMOUNT PAID",
            "GATEWAY",
            "CREATED BY",
            "STATUS",
            "PARTIAL DUE DATE",
            "PARTIAL AMOUNT PAID",
            "LINK TYPE",
            "PAID ON",
          ];

          const csvFinalData = res?.data?.data?.paymentDetails?.map((data: any) => {
            const paidOn = data?.paymentSuccessObject?.created_at ? moment.unix(data?.paymentSuccessObject?.created_at).format("DD MM YYYY hh:mm a") : "--";
            return Object.values({
              "ID/ORDERID ": data?._id,
              "STUDENT NAME":
                data?.studentId?.firstName + " " + data?.studentId?.lastName,
              "TEACHER NAME":
                data?.instructorId?.firstName + " " + data?.instructorId?.lastName,
              COUNTRY: data?.country,
              COURSE: data?.courseId?.title,
              "COORDINATOR NAME": data?.coordinator,
              SESSIONS: data?.totalSession,
              "EXTRA SESSIONS": data?.extraClasses,
              "AMOUNT PAID": "₹" + data?.amount / 100,
              GATEWAY: data?.gateway.toUpperCase(),
              "CREATED BY": data?.createdBy?.firstName + " " + data?.createdBy?.lastName,
              STATUS: data?.paymentStatus,
              "PARTIAL DUE DATE": moment(data?.partialPayDueDate).format(
                "DD MM YYYY hh:mm a"
              ),
              "PARTIAL AMOUNT PAID": "₹" + data?.partialPayment / 100,
              "LINK TYPE": data?.paymentType,
              "PAID ON": paidOn,
            });
          });

        const csvDataWithHeader = [csvHeader];
        if (csvFinalData && csvFinalData.length > 0) {
          csvDataWithHeader.push(...csvFinalData);
        }

          setCsvData(csvDataWithHeader);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Unable to fetch Payment Details");
      });
  };

 

 


  
  useEffect(() => {
    getPaymentData();
  }, [
    showCreateLink,
    totalPages,
    pageCount,
    status,
    dateRange,
    gateway,
    selOption?.student,
  ]);

  return (
    <>
      <Container>
        <Row>
          <Col>
            <CardBasic
              className="mt-3"
              body={
                <>
                  <h6>Filters</h6>
                  <Row>
                    <Col md={3}>
                      <Form.Label className="fw-bold text-secondary fs-12">
                        Date Range
                      </Form.Label>
                      <ReactDatePicker
                        onChange={(e: any) => {
                          setDateRange({
                            startDate: e[0],
                            endDate: e[1],
                          });
                        }}
                        startDate={dateRange.startDate}
                        endDate={dateRange.endDate}
                        selectsRange
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                      />
                    </Col>
                    <Col md={3}>
                      <Form.Label className="fw-bold text-secondary fs-12">
                        Payment Status
                      </Form.Label>
                      <Select
                        options={razorPayStatus}
                        value={status}
                        onChange={setStatus}
                      />
                    </Col>
                    <Col md={3}>
                      <Form.Label className="fw-bold text-secondary fs-12">
                        Gateway
                      </Form.Label>
                      <Select
                        options={gateways}
                        value={gateway}
                        onChange={setGateway}
                      />
                    </Col>
                    <Col md={3}>
                      <Form.Label className="fw-bold text-secondary fs-12">
                        Students
                      </Form.Label>
                      <AsyncSelect
                        name="StudentId"
                        loadOptions={fetchStudentData}
                        onChange={(option: any) =>
                          handelInstructorChanges(option, "student")
                        }
                        isClearable={true}
                      />
                    </Col>
                  </Row>
                </>
              }
            />
          </Col>
        </Row>

        <Card className="mt-4">
          <Card.Body>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <Card.Title>Payment Links</Card.Title>
              <div className="d-flex gap-2 ">
                <ButtonGroup size="sm">
                <Button onClick={createCSVFile} size='sm' className="me-1" >
                    Create CSV Data
                    </Button>
                  {csvData && csvData.length>0 && <CSVLink data={csvData} filename={"payment.csv"}>
                    <Button>Download CSV</Button>
                  </CSVLink> }
                </ButtonGroup>

                <ButtonGroup size="sm">
                  <Button onClick={() => setShowCreateLink(true)}>
                    Create Link
                  </Button>
                </ButtonGroup>
              </div>
            </div>
            <div className="m-4">
              <RazorpayTable
                tableData={tableData}
                reloadData={getPaymentData}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Pagination>
                <Pagination.First />
                <Pagination.Prev
                  onClick={() => {
                    setPageCount(pageCount - 1);
                  }}
                />
                <Pagination.Item>{pageCount}</Pagination.Item>
                <Pagination.Next
                  onClick={() => {
                    setPageCount(pageCount + 1);
                  }}
                />
                <Pagination.Last />
              </Pagination>
            </div>
          </Card.Body>
        </Card>
      </Container>
      <CreateRazorpayLinkModal
        show={showCreateLink}
        setShow={setShowCreateLink}
      />
    </>
  );
}
