import { IAction } from "../interface";
import { IAuthState } from "./interace";

export const loginInitial = (state: IAuthState, action: IAction) => {
  return { ...state, loading: true, loggedIn: false, token: null };
};

export const loginSuccess = (state: IAuthState, action: IAction) => {
  return {
    ...state,
    loading: false,
    loggedIn: !!action?.payload?.token,
    token: action?.payload?.token,
  };
};

export const loginFailure = (state: IAuthState, action: IAction) => {
  return { ...state, loading: false, loggedIn: false, token: null };
};

export const onSetToken = (state: IAuthState, action: IAction) => {
  return {
    ...state,
    loggedIn: !!action?.payload?.token,
    token: action?.payload?.token,
  };
};
