import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import { Calendar, DateRangePicker } from "react-date-range";
import Select from "react-select";
import { addDays, subDays } from "date-fns";
import { AdminService } from "../../../services/admin.service";
import toast from "react-hot-toast";
import { ExportToCsv } from "export-to-csv-fix-source-map";
import CardBasic from "../../../components/Card/card.basic";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface IOptions {
  fieldSeparator: any;
  quoteStrings: any;
  decimalSeparator: any;
  showLabels: any;
  showTitle: any;
  title: any;
  useTextFile: any;
  useBom: boolean;
  useKeysAsHeaders: boolean;
}

const Index = () => {
  const [state, setState] = useState<any>([
    {
      startDate: subDays(new Date(), 7),
      endDate: addDays(new Date(), 1),
      key: "selection",
    },
  ]);

  const [selectValue, setSelectValue] = useState<any>();

  const [data, setData] = useState<any>([]);

  const [csvTypes, setCSVTypes] = useState<any>({});
  const [date, setDate] = useState<any>(new Date());
  const [classesData, setClassesData] = useState<any>();

  const handleOnChange = (ranges: any) => {
    const { selection } = ranges;
    setState([selection]);
  };

  const handleClassesChange = (e: any) => {
    setClassesData({ ...classesData, [e.target.name]: e.target.value });
  };

  const newValueLabelPair = [
    {
      value: "global-enrollment",
      label: "GLOBAL ENROLLMENT",
    },

    {
      value: "internal-enrollment",
      label: "INTERNAL ENROLLMENT",
    },
  ];

  const csvTypesData = async () => {
    await AdminService.getAllCSVTypes()
      .then((res) => {
        if (res.status === 200) {
          const existingCSVTypes = Object.values(res?.data?.data).map(
            (data: any) => ({
              value: data,
              label: data.toUpperCase(),
            })
          );
          setCSVTypes([...existingCSVTypes, ...newValueLabelPair]);
        }
      })
      .catch((err: any) => {
        toast.error("Unable to Get CSV Types Data");
      });
  };

  const handleSelectChange = (e: any, name: any) => {
    setSelectValue({ ...selectValue, [name]: e.value });
  };
  let csvOptions: IOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    showTitle: true,
    title: "CSV Data",
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  };

  const getRenewalCsv = async () => {
    await AdminService.getRenewalCsv(
      classesData.numberOfClassesFrom,
      classesData.numberOfClassesTo
    ).then((res) => {
      if (res.status === 200) {
        console.log(res.data, "renewal");
        setData(res.data);
      }
    });
  };

  useEffect(() => {
    if (classesData) {
      getRenewalCsv();
    }
  }, [classesData]);

  const csvExporter = new ExportToCsv(csvOptions);

  const handleDownload = async () => {
    const startDateString = moment(state[0].startDate).format("YYYY-MM-DD");
    const endDateString = moment(state[0].endDate).format("YYYY-MM-DD");

    if (selectValue?.csvdata === "extra-sessions") {
      await AdminService.getExtraClassesCSV(startDateString, endDateString)
        .then((res) => {
          if (res.status === 200) {
            setData(res?.data?.data);
            if (res?.data?.data?.length === 0) {
              throw Error;
            }
          }
        })
        .catch((err: any) => {
          toast.error(
            "Unable to Get CSV File, Please select a different Date Range"
          );
        });
    }

    if (selectValue?.csvdata === "payment-enrolment") {
      await AdminService.getPaymentEnrollments(startDateString, endDateString)
        .then((res) => {
          if (res.status === 200) {
            setData(res?.data?.data);
            if (res?.data?.data?.length === 0) {
              throw Error;
            }
          }
        })
        .catch((err: any) => {
          toast.error(
            "Unable to Get CSV File, Please select a different Date Range"
          );
        });
    }

    if (selectValue?.csvdata === "dhunguru-discount") {
      await AdminService.getDhunguruDiscountCSV(startDateString, endDateString)
        .then((res) => {
          if (res.status === 200) {
            setData(res?.data?.data);
            if (res?.data?.data?.length === 0) {
              throw Error;
            }
          }
        })
        .catch((err: any) => {
          toast.error(
            "Unable to Get CSV File, Please select a different Date Range"
          );
        });
    }

    if (selectValue?.csvdata === "active-students") {
      await AdminService.getActiveStudentCsv(startDateString, endDateString)
        .then((res) => {
          if (res.status === 200) {
            setData(res?.data?.data?.students);
            if (res?.data?.data?.students?.length === 0) {
              throw Error;
            }
          }
        })
        .catch((err: any) => {
          toast.error(
            "Unable to Get CSV File, Please select a different Date Range"
          );
        });
    }

    if (selectValue?.csvdata === "demo-sessions") {
      await AdminService.getDemoSessionsCsv(startDateString, endDateString)
        .then((res) => {
          console.log(res?.data, "data csv");
          if (res.status === 200) {
            setData(res?.data?.data);
            if (res?.data?.data?.length === 0) {
              throw Error;
            }
          }
        })
        .catch((err: any) => {
          toast.error(
            "Unable to Get CSV File, Please select a different Date Range"
          );
        });
    }

    if (selectValue?.csvdata === "session-recording") {
      await AdminService.getdownloadRecordingForDateRangeCsv(
        startDateString,
        endDateString
      )
        .then((res) => {
          if (res.status === 200) {
            setData(res?.data?.data?.recording);
            if (res?.data?.data?.recording?.length === 0) {
              throw Error;
            }
          }
        })
        .catch((err: any) => {
          toast.error(
            "Unable to Get CSV File, Please select a different Date Range"
          );
        });
    }
    if (selectValue?.csvdata === "bulk-data") {
      await AdminService.getBulkDataCsv(startDateString, endDateString)
        .then((res) => {
          if (res.status === 200) {
            setData(res?.data?.data?.students);
            if (res?.data?.data?.students?.length === 0) {
              throw Error;
            }
          }
        })
        .catch((err: any) => {
          toast.error(
            "Unable to Get CSV File, Please select a different Date Range"
          );
        });
    }
    if (selectValue?.csvdata === "enrollment") {
      await AdminService.getEnrollmentCsv(startDateString, endDateString)
        .then((res) => {
          if (res.status === 200) {
            setData(res?.data?.data);
          }
        })
        .catch((err: any) => {
          toast.error(
            "Unable to Get CSV File, Please select a different Date Range"
          );
        });
    }
    if (selectValue?.csvdata === "student") {
      await AdminService.getStudentCsv(startDateString, endDateString)
        .then((res) => {
          if (res.status === 200) {
            setData(res?.data?.data?.students);
            if (res?.data?.data?.students?.length === 0) {
              throw Error;
            }
          }
        })
        .catch((err: any) => {
          toast.error(
            "Unable to Get CSV File, Please select a different Date Range"
          );
        });
    }
    if (selectValue?.csvdata === "instructor") {
      await AdminService.getInstructorCsv(startDateString, endDateString)
        .then((res) => {
          if (res.status === 200) {
            setData(res?.data?.data?.instructors);
            if (res?.data?.data?.instructors?.length === 0) {
              throw Error;
            }
          }
        })
        .catch((err: any) => {
          toast.error(
            "Unable to Get CSV File, Please select a different Date Range"
          );
        });
    }
    if (selectValue?.csvdata === "invoices") {
      await AdminService.getInvoicesCsv(startDateString, endDateString)
        .then((res) => {
          if (res.status === 200) {
            setData(res?.data?.data?.students);
            if (res?.data?.data?.students?.length === 0) {
              throw Error;
            }
          }
        })
        .catch((err: any) => {
          toast.error(
            "Unable to Get CSV File, Please select a different Date Range"
          );
        });
    }

    if (selectValue?.csvdata === "conversion-ratio") {
      await AdminService.getInstructorConversionCSV(
        startDateString,
        endDateString
      )
        .then((res) => {
          if (res.status === 200) {
            setData(res?.data?.data);
            if (res?.data?.data?.length === 0) {
              throw Error;
            }
          }
        })
        .catch((err: any) => {
          toast.error(
            "Unable to Get CSV File, Please select a different Date Range"
          );
        });
    }

    if (selectValue?.csvdata === "sessions") {
      await AdminService.getSessionsByDateRange(
        startDateString,
        endDateString
      ).then((res) => {
        if (res.status === 200) {
          setData(res?.data);
          if (res?.data === 0) {
            throw Error;
          }
        }
      });
    }

    if (selectValue?.csvdata === "incentives") {
      await AdminService.getIncentivesCSVByDateRange(
        startDateString,
        endDateString
      ).then((res) => {
        if (res.status === 200) {
          setData(res?.data);
          if (res?.data === 0) {
            throw Error;
          }
        }
      });
    }
    if (selectValue?.csvdata === "all-students") {
      await AdminService.getAllStudentsCSV(startDateString, endDateString).then(
        (res) => {
          if (res.status === 200) {
            setData(res?.data);
            if (res?.data?.length === 0) {
              throw Error;
            }
          }
        }
      );
    }
    if (selectValue?.csvdata === "global-enrollment") {
      await AdminService.getGlobalEnrollment().then((res) => {
        if (res.status === 200) {
          setData(res?.data?.data);
          if (res?.data?.length === 0) {
            throw Error;
          }
        }
      });
    }
    if (selectValue?.csvdata === "internal-enrollment") {
      await AdminService.getInternalEnrollment().then((res) => {
        if (res.status === 200) {
          setData(res?.data?.data);
          if (res?.data?.length === 0) {
            throw Error;
          }
        }
      });
    }
    if (selectValue?.csvdata === "all-enrollments") {
      await AdminService.getAllEnrollments(startDateString, endDateString).then((res) => {
        if (res.status === 200) {
          setData(res?.data?.data);
          if (res?.data?.length === 0) {
            throw Error;
          }
        }
      });
    }

    if (selectValue?.csvdata === "Admin Logs") {
      await AdminService.getAdminLogs(startDateString, endDateString).then((res) => {
        if (res.status === 200) {
          setData(res?.data?.data);
          if (res?.data?.length === 0) {
            throw Error;
          }
        }
      });
    }

    if (selectValue === undefined) {
      toast.error("Select CSV Data");
    }
  };

  useEffect(() => {
    csvTypesData();
  }, []);

  useEffect(() => {
    if (data?.length > 1) {
      console.log(data);

      csvExporter.generateCsv(data);
    }
  }, [data]);

  return (
    <>
      <Container>
        <Row className="mt-4">
          <Col>
            <CardBasic
              body={
                <>
                  <div>
                    <Form.Group>
                      <Form.Label className="fw-bold text-secondary fs-12">
                        1. Select type of CSV Data
                      </Form.Label>
                      <Select
                        options={csvTypes}
                        onChange={(e: any) => handleSelectChange(e, "csvdata")}
                      />
                    </Form.Group>
                  </div>
                </>
              }
            />
          </Col>
        </Row>
        {!(
          selectValue?.csvdata === "global-enrollment" ||
          selectValue?.csvdata === "internal-enrollment"
        ) ? (
          selectValue?.csvdata === "renewal" ? (
            // Render this block if csvdata is "renewal"
            <Row className="mt-4">
              <CardBasic
                body={
                  <Row>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label className="fw-bold text-secondary fs-12">
                          Starting Classes
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="numberOfClassesFrom"
                          onChange={handleClassesChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label className="fw-bold text-secondary fs-12">
                          Ending Classes
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="numberOfClassesTo"
                          onChange={handleClassesChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                }
              />
            </Row>
          ) : (
            // Render this block if csvdata is not "renewal"
            <Row className="mt-4">
              <Col>
                <CardBasic
                  body={
                    <div>
                      <Form.Label className="fw-bold text-secondary fs-12">
                        2. Select Date Range
                      </Form.Label>

                      <div>
                        <DateRangePicker
                          onChange={handleOnChange}
                          months={2}
                          moveRangeOnFirstSelection={false}
                          ranges={state}
                          direction="horizontal"
                        />
                      </div>
                    </div>
                  }
                />
              </Col>
            </Row>
          )
        ) : (
          // Render an empty fragment if csvdata is 'global-enrollment'
          <></>
        )}

        <Row className="mt-4">
          <Col>
            <Button onClick={handleDownload}>Download</Button>
          </Col>
        </Row>

        {data.length > 0 && (
          <Row className="mt-4">
            <CardBasic
              body={
                selectValue?.csvdata == "active-students" ||
                  selectValue?.csvdata == "extra-sessions" ||
                  selectValue?.csvdata == "dhunguru-discount" ||
                  selectValue?.csvdata == "student" ||
                  selectValue?.csvdata == "enrollment" ||
                  selectValue?.csvdata == "demo-sessions" ||
                  selectValue?.csvdata == "conversion-ratio" ||
                  selectValue?.csvdata == "sessions" ||
                  selectValue?.csvdata == "renewal" ||
                  selectValue?.csvdata == "payment-enrolment" ||
                  selectValue?.csvdata == "incentives" ||
                  selectValue?.csvdata == "all-students" ||
                  selectValue?.csvdata == "Admin Logs" ||
                  selectValue?.csvdata == "all-enrollments" ? (
                  <Table striped responsive size="sm">
                    <thead>
                      <tr>
                        {Object.keys(data[0]).map((header) => {
                          return (
                            <td className="fw-bold text-secondary fs-12">
                              {header}
                            </td>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((row: any) => {
                        return (
                          <tr>
                            {Object.values(row).map((column: any) => {
                              return (
                                <td className="fw-bold text-secondary fs-12">
                                  {column}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                ) : (
                  <></>
                )
              }
            />
          </Row>
        )}
      </Container>
    </>
  );
};

export default Index;
