import { Box, Card, CardContent, CardMedia, Grid, Button } from "@mui/material";
import React, { useEffect } from "react";
import VerifiedIcon from "@mui/icons-material/Verified";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useAppDispatch, useAppSelector } from "../../../../store/reduxHooks";
import { onFetchInstructorCourse } from "../../../../store/actions/InstructorCourse";
import { useParams } from "react-router";

const style = {
  margin0padding0: { margin: 0, padding: 0 },
  icon: { fontSize: "20px", color: "#1A83FF" },
  displayFlex: { display: "flex", alignItems: "center", gap: 10 },
  p: { fontSize: "12px", color: "#505050" },
  margin0paddingTop2: { margin: 0, paddingTop: "2%" },
  btn: {
    backgroundColor: "#2ecc71",
    fontSize: "9px",
    textTransform: "none",
    color: "white",
    padding: "2px",
    margin: "0",
    "&:hover": {
      backgroundColor: "#2ecc71",
      color: "white",
    },
  },
  btnprice: {
    backgroundColor: "#ff6700",
    fontSize: "9px",
    textTransform: "none",
    color: "white",
    padding: "2px",
    margin: "0",
    marginLeft: "8px",
    "&:hover": {
      backgroundColor: "#ff6700",
      color: "white",
    },
  },
  btn2: {
    backgroundColor: "#e74c3c",
    fontSize: "9px",
    textTransform: "none",
    color: "white",
    padding: "2px",
    margin: "0",
    "&:hover": {
      backgroundColor: "#e74c3c",
      color: "white",
    },
  },
  tablecell: {
    height: "10px !important",
    fontSize: "12px",
    padding: "5px",
  },
};

function CourseDetails() {
  const dispatch = useAppDispatch();
  const course = useAppSelector(
    (state) => state?.instructorCourses?.courseDetail
  );
  const { id } = useParams();
  useEffect(() => {
    dispatch(onFetchInstructorCourse({ id }));
  }, [id]);
  return (
    <>
      {course && (
        <Box sx={{ padding: "0% 3%", height: "100%" }}>
          <h4 style={style.margin0padding0}>Course Details</h4>
          <Card sx={{ height: "100%" }}>
            <CardContent sx={{ height: "100%" }}>
              <Grid container columnSpacing={3}>
                <Grid item xs={3} sx={{ height: "20vh" }}>
                  <CardMedia
                    component="img"
                    sx={{ height: "100%" }}
                    image={course.image}
                  />
                </Grid>
                <Grid item xs={9}>
                  <div
                    style={{ ...style.displayFlex, ...style.margin0padding0 }}
                  >
                    <h4 style={style.margin0padding0}>{course.courseName}</h4>
                    {course.approve && <VerifiedIcon sx={style.icon} />}
                  </div>
                  <h6
                    style={{
                      ...style.margin0padding0,
                      ...style.margin0paddingTop2,
                    }}
                  >
                    Speciality
                  </h6>
                  <p style={{ ...style.margin0padding0, ...style.p }}>
                    {course.speciality}
                  </p>
                  {course.approve ? (
                    <Button sx={style.btn}>Approved</Button>
                  ) : (
                    <Button sx={style.btn2}>Not Approved</Button>
                  )}
                  <Button sx={style.btnprice}>
                    {course.price_per_class}
                    Rs/class
                  </Button>
                </Grid>
              </Grid>
              <h6
                style={{
                  ...style.margin0padding0,
                  ...style.margin0paddingTop2,
                }}
              >
                Discount
              </h6>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 150 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" sx={style.tablecell}>
                        No. of classes
                      </TableCell>
                      <TableCell align="center" sx={style.tablecell}>
                        Discount
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody></TableBody>
                  {course?.discount?.map((data: any) => (
                    <TableRow
                      key={data?.num_classes}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        sx={style.tablecell}
                      >
                        {data?.num_classes || "NA"}
                      </TableCell>
                      <TableCell align="center" sx={style.tablecell}>
                        {data?.discount || "NA"}%
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Box>
      )}
    </>
  );
}

export default CourseDetails;
