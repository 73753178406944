import React, { useState } from "react";
import { Badge, Dropdown, Pagination, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import { BiDotsVertical } from "react-icons/bi";
import { IoCopy, IoSync, IoTrashBinSharp } from "react-icons/io5";
import { useNavigate } from "react-router";
import { AdminService } from "../../services/admin.service";
import CustomToggle from "../Menu/CustomMenu";
import { FontBold, NormalText } from "../Text/TableText.formats";
import "./table.css";
import moment from "moment";
import PaymentLinkDetailModal from "../Modals/PaymentLinkDetail.modal";
import { FaEye } from "react-icons/fa";

interface IRazorPay {
  tableData: any;
  reloadData: any;
}

export default function RazorpayTable({ tableData, reloadData }: IRazorPay) {
  const navigate = useNavigate();

  const paymentSync = async (linkId: any) => {
    await AdminService.paymentSync(linkId).then((res: any) => {
      if (res.status === 200) {
        toast.success(res.data.message || res.data);
        reloadData();
      }
    });
  };

  const deleteRazorPayLink = async (linkId: any) => {
    await AdminService.deleteRazorPayLink(linkId).then((res) => {
      if (res.status === 200) {
        toast.success("Success: Payment Link Deleted");
        reloadData()
      }
    });
  };


  const paytmSync = async (linkId: any) => {
    await AdminService.paytmSync(linkId).then((res: any) => {
      if (res.status === 200) {
        toast.success(res.data.message || res.data);
        reloadData();
      }
    });
  };

  const cashfreeSync = async (linkId: any) => {
    await AdminService.cashfreeSync(linkId).then((res: any) => {
      if (res.status === 200) {
        toast.success(res.data.message || res.data);
        reloadData();
      }
    });
  };
  const [selectedPaymentId, setSelectedPaymentId] = useState<any>(undefined);

  return (
    <>
      <Table striped borderless hover responsive size="sm">
        <thead>
          <tr className="dhun-table-header text-secondary">
            <th>#</th>
            <th>Id/OrderId</th>
            <th>Student Name</th>
            <th>Teacher Name</th>
            <th>Country</th>
            <th>Course</th>
            <th>Coordinator Name</th>
            <th>Sessions</th>
            <th>Extra Sess.</th>
            <th>Amount Paid</th>
            <th>Gateway</th>
            <th>Created By</th>
            <th>Status</th>
            <th>Partial Due Date</th>
            <th>Partial Amount Paid</th>
            <th>Paid On</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {tableData && tableData.length > 0
            ? tableData.map((data: any, index: any) => {
            const paidOn = data?.paymentSuccessObject?.created_at ? moment.unix(data?.paymentSuccessObject?.created_at).format("DD MM YYYY hh:mm a") : "--";
              return (
                <tr>
                  <td>
                    <NormalText value={index + 1} />
                  </td>
                  <td>
                    <div className="d-flex">
                      {data?.paymentStatus === "completed" ? (
                        <FontBold
                          value={
                            data.gateway === "cashfree"
                              ? data?.paymentSuccessObject?.data?.link_id ||
                              data?.paymentSuccessObject?.link_id
                              : data?.paymentSuccessObject?.payload?.order
                                ?.entity.id || data._id
                          }
                        />
                      ) : (
                        <FontBold value={data._id} />
                      )}
                      <FaEye
                        className="text-primary ms-1 cursor-pointer"
                        onClick={() => setSelectedPaymentId(data._id)}
                      />
                    </div>
                  </td>

                  <td>
                    <u
                      className="fst-italic text-primary fs-12 fs-underline cursor-pointer"
                      onClick={() => {
                        navigate(
                          "/admin/search/student/detail/" +
                          data?.studentId?._id
                        );
                      }}
                    >
                      {data.studentId
                        ? data.studentId.firstName +
                        " " +
                        data.studentId.lastName
                        : "N.A."}
                    </u>
                  </td>
                  <td>
                    <u
                      className="fst-italic text-primary fs-12 fs-underline cursor-pointer"
                      onClick={() => {
                        navigate(
                          "/admin/search/instructor/detail/" +
                          data?.instructorId?._id
                        );
                      }}
                    >
                      {data.instructorId
                        ? data.instructorId.firstName +
                        " " +
                        data.instructorId.lastName
                        : "N.A."}
                    </u>
                  </td>
                  <td>
                    <div className="fst-italic fs-12 cursor-pointer">
                      {data.country ? data.country : "--"}
                    </div>
                  </td>
                  <td>
                    <div className="fst-italic fs-12 cursor-pointer">
                      {data.courseId ? data.courseId.title : "N.A."}
                    </div>
                  </td>
                  {data?.coordinator ? (
                    <td>
                      <div className="fst-italic fs-12 cursor-pointer">
                        {data?.coordinator}{" "}
                      </div>
                    </td>
                  ) : (
                    <td>
                      <Badge
                        bg="danger"
                        className="me-1 fst-italic fs-12 cursor-pointer"
                      >
                        "N/A"
                      </Badge>
                    </td>
                  )}
                  {data?.totalSession ? (
                    <td>
                      <div className="fst-italic fs-12 cursor-pointer">
                        {data?.totalSession}{" "}
                      </div>
                    </td>
                  ) : (
                    <td>
                      <div className="fst-italic fs-12 cursor-pointer">0</div>
                    </td>
                  )}

                  {data?.extraClasses ? (
                    <td>
                      <div className="fst-italic fs-12 cursor-pointer">
                        {data?.extraClasses}{" "}
                      </div>
                    </td>
                  ) : (
                    <td>
                      <div className="fst-italic fs-12 cursor-pointer">0</div>
                    </td>
                  )}
                  {data?.amount ? (
                    <td>
                      <div className="fst-italic fs-12 cursor-pointer">
                        ₹{data?.amount / 100}{" "}
                      </div>
                    </td>
                  ) : (
                    <td>
                      <div className="fst-italic fs-12 cursor-pointer">0</div>
                    </td>
                  )}

                  <td>
                    {(data.gateway && data.gateway.toUpperCase()) || "---"}
                  </td>
                  <td>
                    {data.createdBy
                      ? data.createdBy.firstName +
                      " " +
                      data.createdBy.lastName
                      : "N.A."}
                  </td>

                  {data.paymentStatus === "pending" ? (
                    <td>
                      <u className="fst-italic text-primary fs-12 cursor-pointer">
                        <Badge bg="danger" className="me-1">
                          {data.paymentStatus?.toUpperCase()}{" "}
                        </Badge>
                      </u>
                    </td>
                  ) : data.paymentStatus === "partial-paid" ? (
                    <td>
                      <u className="fst-italic text-primary fs-12 cursor-pointer">
                        <Badge bg="warning" className="me-1">
                          {data.paymentStatus?.toUpperCase()}{" "}
                        </Badge>
                      </u>
                    </td>
                  ) : (
                    <td>
                      <u className="fst-italic text-primary fs-12 cursor-pointer">
                        <Badge bg="success" className="me-1">
                          {data.paymentStatus?.toUpperCase()}{" "}
                        </Badge>
                      </u>
                    </td>
                  )}
                  <td>
                    {data?.partialPayDueDate &&
                      data.paymentStatus != "completed"
                      ? moment(data?.partialPayDueDate).format(
                        "DD MM YYYY hh:mm a"
                      )
                      : "---"}
                  </td>
                 
                  <td>
                    {data?.paymentPartialObject &&
                      data.paymentStatus != "completed" ? (
                      <span>
                        ₹
                        {data?.paymentPartialObject?.payload?.order?.entity
                          ?.amount_paid / 100}
                      </span>
                    ) : (
                      "---"
                    )}
                  </td>
                  <td>
                    {
                      paidOn
                    }
                  </td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle
                        as={CustomToggle}
                        id="dropdown-custom-components"
                      >
                        <BiDotsVertical />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {data.paymentDetails && (
                          <Dropdown.Item
                            className="text-secondary fs-12 align-items-center d-flex fw-bold"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                data.paymentDetails.short_url ||
                                data.paymentDetails.link_url ||
                                data.paymentDetails.data.body.shortUrl
                              );
                              toast.success("Link Copied");
                            }}
                          >
                            <IoCopy className="text-secondary me-1" /> Copy
                            Link
                          </Dropdown.Item>
                        )}

                        {/* {data.gateway === 'paytm' &&  */}
                        <Dropdown.Item
                          className="text-success fs-12 align-items-center d-flex fw-bold"
                          onClick={() => {
                            data.gateway === "rzp" && paymentSync(data?._id);
                            data.gateway === "cashfree" &&
                              cashfreeSync(data?._id);
                            data.gateway === "paytm" && paytmSync(data?._id);
                          }}
                        >
                          <IoSync className="text-success me-1" /> Data Sync
                          (paytm only)
                        </Dropdown.Item>
                        {data.paymentStatus && data.paymentStatus == 'pending' && (
                          <Dropdown.Item
                            className="text-danger fs-12 align-items-center d-flex fw-bold"
                            onClick={() => {
                              deleteRazorPayLink(data?._id);
                            }}
                          >
                            <IoTrashBinSharp className="text-danger me-1" /> Delete Payment Link
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              );
            })
            :  
            <tr className="text-center">
              <td colSpan={16}>No Data Found</td> 
            </tr>
            }
        </tbody>
      </Table>

      <PaymentLinkDetailModal
        paymentId={selectedPaymentId}
        onHide={() => setSelectedPaymentId(undefined)}
      />
    </>
  );
}
